import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';
import Media from '../../components/media';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';
import {
  Fold,
  FoldInner,
  FoldHeader,
  FoldSuptitle,
  FoldLead,
  FoldBody,
  FoldBlock,
  FoldTitle
} from '../../styles/fold';
import { FoldMosaicFeatures } from '../../components/mosaic';

import MediaCompanyWWDCover from '../../media/layout/company/company-wwd--cover.jpg';
import SectionTeamOpenings from './_section-team-openings';

const values = [
  {
    title: 'Transparency and Access',
    body: 'We promote open-source tools and data. We&apos;re committed to ensuring the best data and tools on the planet are available to everyone.'
  },
  {
    title: 'Transform',
    body: 'We want to create a lasting impact and a brighter future. We believe in taking action and driving change.'
  },

  {
    title: 'Diversity, Equity, Inclusion and Belonging',
    body: 'We seek diversity, equity, and inclusion in the ideas, people, and spaces we engage.'
  },

  {
    title: 'Innovation',
    body: 'We&apos;re inspired to push the envelope and solve problems in unique and unusual ways.'
  },

  {
    title: 'Passion',
    body: 'We&apos;re curious about how things work and are unafraid to ask hard questions. We energize, challenge, and promote our partners and each other&apos;s ideas.'
  },

  {
    title: 'Collaboration',
    body: 'We support, contribute to, and actively engage the various communities that touch our work. Exclusivity and competition aren&apos;t our culture.'
  }
];

const Company = ({ location }) => {
  return (
    <Layout location={location} title='Company'>
      <Inpage as='section'>
        <InpageHeader leadLayout='column'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                We improve the tools that improve the planet
              </InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                Development Seed is an engineering and product company that is
                accelerating the application of earth data to our biggest global
                challenges. We leverage massive earth data, cloud computing,
                geospatial AI, and thoughtful product development to 10x your
                data scientists and empower your decisionmakers with a clear
                view of a rapidly changing planet.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          <Media
            size='cover'
            decoration='top'
            src={MediaCompanyWWDCover}
            alt='Team meeting'
          />

          <Fold>
            <FoldInner>
              <FoldHeader>
                <FoldSuptitle>What we stand for</FoldSuptitle>
                <FoldTitle>What makes us different makes us stronger</FoldTitle>
                <FoldLead>
                  <p>
                    We empower each other to bring unique perspectives and
                    experiences to work, and we continuously seek new ways to do
                    so.
                  </p>
                </FoldLead>
              </FoldHeader>
              <FoldBody>
                <FoldBlock>
                  <FoldMosaicFeatures items={values} />
                </FoldBlock>
              </FoldBody>
            </FoldInner>
          </Fold>

          <SectionTeamOpenings />
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Company;

Company.propTypes = {
  location: PropTypes.object
};
