import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button } from '@devseed-ui/button';
import { media, visuallyHidden } from '@devseed-ui/theme-provider';

import { Widont } from '../../components/widont';
import {
  Section,
  SectionHeader,
  SectionHeadline,
  SectionTitle,
  SectionLead,
  SectionBody
} from '../../styles/section';
import UniversalGridder from '../../styles/universal-gridder';
import ContinuumImageGallery from '../../components/continuum-image-gallery';
import { TileTitle, MosaicNumbers } from '../../components/mosaic';

import { stripe } from '../../styles/motifs';

import MediaSliderItem01 from '../../media/layout/company/company-slider-item-01.jpg';
import MediaSliderItem02 from '../../media/layout/company/company-slider-item-02.jpg';
import MediaSliderItem03 from '../../media/layout/company/company-slider-item-03.jpg';
import MediaSliderItem04 from '../../media/layout/company/company-slider-item-04.jpg';
import MediaSliderItem05 from '../../media/layout/company/company-slider-item-05.jpg';
import TeamMap from './_team-map';

const MosaicNumbersCompany = styled(MosaicNumbers)`
  ${TileTitle} strong {
    font-size: 6rem;

    ${media.smallUp`
      font-size: 8rem;
    `}

    ${media.mediumUp`
      font-size: 10rem;
    `}

    ${media.largeUp`
      font-size: 12rem;
    `}
  }
`;

const InNumbersTitle = styled.h1`
  ${visuallyHidden}
`;

const inNumbers = [
  {
    value: 20,
    unit: '+',
    title: 'Languages spoken',
    body: 'Part of having a globally diverse team means our team members all bring their unique backgrounds and life experiences to enrich our organization&apos;s culture. Many of our team members are multi-lingual, and we love celebrating and learning from each other.'
  },
  {
    value: 15,
    unit: '+',
    title: 'Countries',
    body: 'You can find our team members around the globe. Whether working from one of our three offices or from their home or co-working space, our global culture is one of our greatest strengths. We have a strong European presence in addition to team members in Peru, Brazil, Lebanon, India, New Zealand, Australia, Canada, and the U.S.'
  }
];

const sectionBodyTeamOpeningsAttrs = (props) => ({
  as: 'div',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
});

const SectionBodyTeamOpenings = styled(UniversalGridder).attrs(
  sectionBodyTeamOpeningsAttrs
)`
  &::before {
    ${stripe};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-row: 1;
    grid-column-start: content-2;
    grid-column-end: span full-end;
    top: 100%;
    transform: translate(0, calc(-100% + 1rem));

    ${media.smallUp`
      transform: translate(0, calc(-100% + 2rem));
    `}

    ${media.mediumUp`
      transform: translate(0, calc(-100% + 3rem));
      grid-column-start: content-3;
    `}

    ${media.largeUp`
      transform: translate(0, calc(-100% + 4rem));
      grid-column-start: content-4;
    `}

    ${media.xlargeUp`
      transform: translate(0, calc(-100% + 6rem));
      grid-column-start: content-3;
    `}
  }
`;

const imageList = [
  { url: MediaSliderItem01 },
  { url: MediaSliderItem02 },
  { url: MediaSliderItem03 },
  { url: MediaSliderItem04 },
  { url: MediaSliderItem05 }
];

const teamMapSectionHeaderGrid = {
  smallUp: ['content-start', 'content-end'],
  mediumUp: ['content-start', 'content-5'],
  largeUp: ['content-start', 'content-7']
};

const TeamMapSectionHeader = styled(SectionHeader)``;

const TeamMapSectionBody = styled(SectionBody)`
  grid-column: content-start / content-end;
  min-height: 20rem;

  ${media.mediumUp`
    grid-column: content-5 / full-end;
    grid-row: 1;
    margin: -5rem 0 -7rem 0;
  `}

  ${media.largeUp`
    grid-column: content-7 / full-end;
    margin: -7rem 0 -10rem 0;
  `}
`;

export default function SectionTeamOpenings() {
  return (
    <>
      <Section>
        <TeamMapSectionHeader grid={teamMapSectionHeaderGrid}>
          <SectionHeadline>
            <SectionTitle>
              <Widont>An international team making a difference</Widont>
            </SectionTitle>
          </SectionHeadline>
          <SectionLead>
            <p>
              We are artists, rocket scientists, teachers, designers, engineers,
              data scientists, and public health researchers working together
              for a future-positive world.
            </p>
            <Button
              forwardedAs={Link}
              variation='primary-fill'
              size='xlarge'
              to='/team'
              title='Visit Team page'
            >
              Meet the team
            </Button>
          </SectionLead>
        </TeamMapSectionHeader>
        <TeamMapSectionBody>
          <TeamMap />
        </TeamMapSectionBody>
      </Section>

      <InNumbersTitle>In numbers</InNumbersTitle>
      <MosaicNumbersCompany items={inNumbers} />

      <Section>
        <SectionBodyTeamOpenings>
          <ContinuumImageGallery list={imageList} alt='Team photo' />
        </SectionBodyTeamOpenings>
      </Section>
    </>
  );
}
