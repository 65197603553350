import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import useMediaAware from '../../utils/media-query-aware-hook';

import housesIcon from './houses.png';

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN;

const OFFICES = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'Washington DC',
        type: 'office'
      },
      geometry: {
        type: 'Point',
        coordinates: [-77.02455, 38.90639]
      }
    },
    {
      type: 'Feature',
      properties: {
        name: 'Lisbon',
        type: 'office'
      },
      geometry: {
        type: 'Point',
        coordinates: [-9.12858, 38.71818]
      }
    },
    {
      type: 'Feature',
      properties: {
        name: 'Ayacucho',
        type: 'office'
      },
      geometry: {
        type: 'Point',
        coordinates: [-74.22263, -13.15567]
      }
    }
  ]
};

const EARTH_AXIS_TILT = -23.5;

function setupAnimation(mbMap) {
  // Setup rotating animation.
  // Complete a revolution every two minutes.
  const secondsPerRevolution = 120;

  let userInteracting = false;

  function spinGlobe() {
    if (!userInteracting) {
      let distancePerSecond = 360 / secondsPerRevolution;
      const center = mbMap.getCenter();
      if (center.lat < 0) {
        center.lat = Math.min(0, center.lat + distancePerSecond);
      } else {
        center.lat = Math.max(0, center.lat - distancePerSecond);
      }
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      mbMap.easeTo({
        center,
        duration: 1000,
        easing: (n) => n
      });
    }
  }

  // Pause spinning on interaction
  mbMap.on('mousedown', () => {
    userInteracting = true;
  });
  mbMap.on('touchstart', () => {
    userInteracting = true;
  });

  // Restart spinning the globe when interaction is complete
  mbMap.on('mouseup', () => {
    userInteracting = false;
    spinGlobe();
  });
  mbMap.on('touchend', () => {
    userInteracting = false;
    spinGlobe();
  });

  // These events account for cases where the mouse has moved
  // off the map, so 'mouseup' will not be fired.
  mbMap.on('dragend', () => {
    userInteracting = false;
    spinGlobe();
  });
  mbMap.on('pitchend', () => {
    userInteracting = false;
    spinGlobe();
  });
  mbMap.on('rotateend', () => {
    userInteracting = false;
    spinGlobe();
  });

  // When animation is complete, start spinning if there is no ongoing interaction
  mbMap.on('moveend', () => {
    spinGlobe();
  });

  // Start spinning the globe
  spinGlobe();
}

export default function TeamMap() {
  const mapContainer = useRef();
  const mapRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);

  const [ref, media] = useMediaAware();
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    async function load() {
      const response = await fetch(
        'https://developmentseed.org/now/team-dso.geojson'
      );
      const data = await response.json();
      setTeamData(data);
    }

    load();
  }, []);

  useEffect(() => {
    if (!teamData || !mapLoaded) return;

    mapRef.current.addSource('team', {
      type: 'geojson',
      data: teamData
    });

    // Add a symbol layer
    mapRef.current.addLayer({
      id: 'team-markers',
      type: 'circle',
      source: 'team',
      paint: {
        'circle-color': '#cf3f02'
      }
    });
  }, [teamData, mapLoaded]);

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  useEffect(() => {
    if (!mapboxgl.accessToken) return;

    const mbMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/devseed/ckyjz196q5m3r14mh4o1srg5s',
      logoPosition: 'bottom-left',
      pitchWithRotate: false,
      dragRotate: false,
      zoom: 2,
      maxZoom: 14,
      projection: 'globe'
    });

    mapRef.current = mbMap;
    window.mbMap = mbMap;

    // Set earth axis tilt.
    mbMap.rotateTo(EARTH_AXIS_TILT);

    // Disable zooming
    mbMap.boxZoom.disable();
    mbMap.scrollZoom.disable();
    mbMap.doubleClickZoom.disable();
    mbMap.touchZoomRotate.disable();

    mbMap.loadImage(housesIcon, (error, image) => {
      if (error) throw error;
      mbMap.addImage('houses', image);
    });

    mbMap.on('load', () => {
      setMapLoaded(true);

      // Setup rotating animation.
      setupAnimation(mbMap);

      mbMap.addSource('offices', {
        type: 'geojson',
        data: OFFICES
      });

      // Add a symbol layer
      mbMap.addLayer({
        id: 'offices-markers',
        type: 'symbol',
        source: 'offices',
        layout: {
          'icon-image': 'houses',
          // get the title name from the source's "title" property
          'text-field': ['get', 'name'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [-1, 0],
          'text-anchor': 'right'
        },
        paint: {
          'text-color': '#6D7278'
        }
      });
    });

    return () => {
      mbMap.remove();
      mapRef.current = undefined;
    };
  }, []);

  const isMediumUp = media?.isMediumUp();

  useEffect(() => {
    if (!mapRef.current) return;

    if (isMediumUp) {
      mapRef.current.setZoom(2);
    } else {
      mapRef.current.setZoom(1);
    }
  }, [isMediumUp]);

  return mapboxgl.accessToken ? (
    <div ref={mapContainer} />
  ) : (
    <div>
      MB Token is missing in env: <code>GATSBY_MAPBOX_TOKEN</code>
    </div>
  );
}
