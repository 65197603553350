import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { media } from '@devseed-ui/theme-provider';

import {
  ContinuumDragScroll,
  ContinuumDragScrollWrapper
} from '../styles/fold-continuum';
import { ContinuumScrollIndicator } from '../styles/continuum-scroll-indicator';
import Continuum, { ContinuumItem } from './continuum';
import Media from './media';

const GalleryDragScrollWrapper = styled(ContinuumDragScrollWrapper)`
  z-index: 2;
  grid-row: 1;
  grid-column: 1 / -1;
  width: calc(100vw - var(--scrollbar-width));
`;

const GalleryContinuum = styled(Continuum)`
  overflow: visible;

  img {
    min-width: auto;
  }

  li:nth-child(odd) img {
    height: 16rem;

    ${media.mediumUp`
      height: 24rem;
    `}

    ${media.largeUp`
      height: 32rem;
    `}
  }

  li:nth-child(even) img {
    height: 24rem;

    ${media.mediumUp`
      height: 32rem;
    `}

    ${media.largeUp`
      height: 40rem;
    `}
  }
`;

export default function ContinuumImageGallery({ className, list, alt }) {
  return (
    <GalleryDragScrollWrapper className={className}>
      <ContinuumScrollIndicator />
      <ContinuumDragScroll hideScrollbars={false}>
        <GalleryContinuum>
          {list.map((img) => (
            <ContinuumItem key={img.url}>
              <Media decoration='none' src={img.url} alt={img.alt || alt} />
            </ContinuumItem>
          ))}
        </GalleryContinuum>
      </ContinuumDragScroll>
    </GalleryDragScrollWrapper>
  );
}

ContinuumImageGallery.propTypes = {
  className: T.string,
  list: T.arrayOf(T.object),
  alt: T.string
};
